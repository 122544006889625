// Certificates of Conformance Import Files
import D100_CoC from './assets/D100_CoC.pdf'
import D200_CoC from './assets/D200_CoC.pdf'
import J240_CoC from './assets/J240_CoC.pdf'
import J270_CoC from './assets/J270_CoC.pdf'
import J720_CoC from './assets/J720_CoC.pdf'
import J724_CoC from './assets/J724_CoC.pdf'
import J730_CoC from './assets/J730_CoC.pdf'
import J736_CoC from './assets/J736_CoC.pdf'
import J744_CoC from './assets/J744_CoC.pdf'
import J750_CoC from './assets/J750_CoC.pdf'
import K420_CoC from './assets/K420_CoC.pdf'
import P330_CoC from './assets/P330_CoC.pdf'
import P344_CoC from './assets/P344_CoC.pdf'
import P348_CoC from './assets/P348_CoC.pdf'
import P350_CoC from './assets/P350_CoC.pdf'
import P400_CoC from './assets/P400_CoC.pdf'
import P470_CoC from './assets/P470_CoC.pdf'
import P500_CoC from './assets/P500_CoC.pdf'
import P545_CoC from './assets/P545_CoC.pdf'
import P620_CoC from './assets/P620_CoC.pdf'
import P730_CoC from './assets/P730_CoC.pdf'
import P900_CoC from './assets/P900_CoC.pdf'
import P940_CoC from './assets/P940_CoC.pdf'
import P941_CoC from './assets/P941_CoC.pdf'
import P942_CoC from './assets/P942_CoC.pdf'
import P943_CoC from './assets/P943_CoC.pdf'
import P944_CoC from './assets/P944_CoC.pdf'
import P945_CoC from './assets/P945_CoC.pdf'
import P948_CoC from './assets/P948_CoC.pdf'
import T124_CoC from './assets/T124_CoC.pdf'
import T130_CoC from './assets/T130_CoC.pdf'
import T160_CoC from './assets/T160_CoC.pdf'
import T165_CoC from './assets/T165_CoC.pdf'
import T240_CoC from './assets/T240_CoC.pdf'
import T340_CoC from './assets/T340_CoC.pdf'
import T344_CoC from './assets/T344_CoC.pdf'
import T346_CoC from './assets/T346_CoC.pdf'
import T400_CoC from './assets/T400_CoC.pdf'
import T500_CoC from './assets/T500_CoC.pdf'
import T560_CoC from './assets/T560_CoC.pdf'
import T564_CoC from './assets/T564_CoC.pdf'
import T660_CoC from './assets/T660_CoC.pdf'
import T680_CoC from './assets/T680_CoC.pdf'
import T735_CoC from './assets/T735_CoC.pdf'
import T750_CoC from './assets/T750_CoC.pdf'
import T760_CoC from './assets/T760_CoC.pdf'
import T840_CoC from './assets/T840_CoC.pdf'
import T850_CoC from './assets/T850_CoC.pdf'
import T860_CoC from './assets/T860_CoC.pdf'
import V120_CoC from './assets/V120_CoC.pdf'
import V124_CoC from './assets/V124_CoC.pdf'
import V180_CoC from './assets/V180_CoC.pdf'
import V210_CoC from './assets/V210_CoC.pdf'
import V220_CoC from './assets/V220_CoC.pdf'
import V230_CoC from './assets/V230_CoC.pdf'
import V250_CoC from './assets/V250_CoC.pdf'
import V280_CoC from './assets/V280_CoC.pdf'
import V340_CoC from './assets/V340_CoC.pdf'
import V344_CoC from './assets/V344_CoC.pdf'
import V346_CoC from './assets/V346_CoC.pdf'
import V360_CoC from './assets/V360_CoC.pdf'
import V365_CoC from './assets/V365_CoC.pdf'
import V375_CoC from './assets/V375_CoC.pdf'
import V380_CoC from './assets/V380_CoC.pdf'
import V385_CoC from './assets/V385_CoC.pdf'
import V410_CoC from './assets/V410_CoC.pdf'
import V420_CoC from './assets/V420_CoC.pdf'
import V450_CoC from './assets/V450_CoC.pdf'
import V460_CoC from './assets/V460_CoC.pdf'
import V470_CoC from './assets/V470_CoC.pdf'
import V490_CoC from './assets/V490_CoC.pdf'
import V545_CoC from './assets/V545_CoC.pdf'
import V550_CoC from './assets/V550_CoC.pdf'
import V660_CoC from './assets/V660_CoC.pdf'
import V680_CoC from './assets/V680_CoC.pdf'
import V720_CoC from './assets/V720_CoC.pdf'
import V730_CoC from './assets/V730_CoC.pdf'
import V860_CoC from './assets/V860_CoC.pdf'
import V880_CoC from './assets/V880_CoC.pdf'

// Statements of Volatility Import Files
import J724_SOV from './assets/J724_SOV.pdf'
import J730_SOV from './assets/J730_SOV.pdf'
import K420_SOV from './assets/K420_SOV.pdf'
import P330_SOV from './assets/P330_SOV.pdf'
import P344_SOV from './assets/P344_SOV.pdf'
import P348_SOV from './assets/P348_SOV.pdf'
import P350_SOV from './assets/P350_SOV.pdf'
import P900_SOV from './assets/P900_SOV.pdf'
import P940_P941_P942_P943_P944_P945_P948_SOV from './assets/P940_P941_P942_P943_P944_P945_P948_SOV.pdf'
import T165_SOV from './assets/T165_SOV.pdf'
import T344_SOV from './assets/T344_SOV.pdf'
import T346_SOV from './assets/T346_SOV.pdf'
import T680_SOV from './assets/T680_SOV.pdf'
import T735_SOV from './assets/T735_SOV.pdf'
import V120_SOV from './assets/V120_SOV.pdf'
import V124_SOV from './assets/V124_SOV.pdf'
import V210_SOV from './assets/V210_SOV.pdf'
import V220_SOV from './assets/V220_SOV.pdf'
import V230_SOV from './assets/V230_SOV.pdf'
import V250_SOV from './assets/V250_SOV.pdf'
import V340_SOV from './assets/V340_SOV.pdf'
import V360_SOV from './assets/V360_SOV.pdf'
import V375_SOV from './assets/V375_SOV.pdf'
import V410_SOV from './assets/V410_SOV.pdf'
import V420_SOV from './assets/V420_SOV.pdf'
import V450_SOV from './assets/V450_SOV.pdf'
import V470_SOV from './assets/V470_SOV.pdf'
import V490_SOV from './assets/V490_SOV.pdf'
import V545_SOV from './assets/V545_SOV.pdf'
import V550_SOV from './assets/V550_SOV.pdf'

// STEP Import Files
import D100_STEP from './assets/D100_17A100C.STEP'
import J240_J730_J750_STEP from './assets/J240_J730_J750_21AXXXA_GENERIC_SILVER.STEP'
import J270_STEP from './assets/J270_21a270a.STEP'
import J720_STEP from './assets/J720_21M720H.STEP'
import J724_STEP from './assets/J724_21A724D.STEP'
import K420_STEP from './assets/K420_24A420A.STEP'
import P344_P348_P350_P730_STEP from './assets/P344_P348_P350_P730_23MXXXA_GENERIC_SMALL_.STEP'
import P940_STEP from './assets/P940_23A940H.STEP'
import T124_T240_T340_T344_T346_T750_T760_T860_STEP from './assets/T124_T240_T340_T344_T346_T750_T760_T860_28AXXXA_GENERIC_SILVER.STEP'
import T500_STEP from './assets/T500_28A500A.STEP'
import T560_T564_STEP from './assets/T560_T564_28A560C.STEP'
import T660_STEP from './assets/T660_28A660A.STEP'
import T735_STEP from './assets/T735_28A735B.STEP'
import V344_V346_STEP from './assets/V344_V346_22A346C.STEP'
import V375_STEP from './assets/V375_22A375A.STEP'

// CE Certificate Import Files
import P500_CE from './assets/P500_CE.pdf'
import P545_CE from './assets/P545_CE.pdf'

// Block Diagram Import Files
import P330_BD from './assets/P330_A_BD.pdf'
import P348_BD from './assets/P348_B_BD.pdf'
import P350_BD from './assets/P350_B_BD.pdf'
import P400_BD from './assets/P400_F_BD.pdf'
import P470_BD from './assets/P470_C_BD.pdf'
import P500_BD from './assets/P500_E_BD.pdf'
import P545_BD from './assets/P545_B_BD.pdf'
import P620_BD from './assets/P620_C_BD.pdf'
import P730_BD from './assets/P730_D_BD.pdf'
import P900_BD from './assets/P900_A_BD.pdf'
import P940_BD from './assets/P940_B_BD.pdf'
import P941_BD from './assets/P941_B_BD.pdf'
import P945_BD from './assets/P945_B_BD.pdf'
import T124_BD from './assets/T124_A_BD.pdf'
import T130_BD from './assets/T130_C_BD.pdf'
import T340_BD from './assets/T340_C_BD.pdf'
import T344_T346_BD from './assets/T344_T346_D_BD.pdf'
import T500_BD from './assets/T500_C_BD.pdf'
import T560_T564_BD from './assets/T560_T564_L_BD.pdf'
import T660_BD from './assets/T660_E_BD.pdf'
import T680_BD from './assets/T680_C_BD.pdf'
import T840_BD from './assets/T840_A_BD.pdf'
import V120_BD from './assets/V120_C_BD.pdf'
import V124_BD from './assets/V124_B_BD.pdf'
import V210_BD from './assets/V210_D_BD.pdf'
import V220_BD from './assets/V220_E_BD.pdf'
import V230_BD from './assets/V230_D_BD.pdf'
import V250_BD from './assets/V250_B_BD.pdf'
import V280_BD from './assets/V280_B_BD.pdf'
import V340_BD from './assets/V340_D_BD.pdf'
import V344_V346_BD from './assets/V344_V346_C_BD.pdf'
import V360_BD from './assets/V360_C_BD.pdf'
import V365_BD from './assets/V365_B_BD.pdf'
import V375_BD from './assets/V375_E_BD.pdf'
import V410_BD from './assets/V410_B_BD.pdf'
import V420_BD from './assets/V420_E_BD.pdf'
import V450_BD from './assets/V450_G_BD.pdf'
import V470_BD from './assets/V470_G_BD.pdf'
import V490_BD from './assets/V490_E_BD.pdf'
import V545_BD from './assets/V545_E_BD.pdf'
import V660_BD from './assets/V660_B_BD.pdf'
import V680_BD from './assets/V680_C_BD.pdf'
import V720_BD from './assets/V720_D_BD.pdf'
import V880_BD from './assets/V880_D_BD.pdf'

// RoHS Certificate Import Files
import D100_RoHS from './assets/D100_RoHS.pdf'
import D200_RoHS from './assets/D200_RoHS.pdf'
import J240_RoHS from './assets/J240_RoHS.pdf'
import J270_RoHS from './assets/J270_RoHS.pdf'
import J720_RoHS from './assets/J720_RoHS.pdf'
import J724_RoHS from './assets/J724_RoHS.pdf'
import J730_RoHS from './assets/J730_RoHS.pdf'
import J736_RoHS from './assets/J736_RoHS.pdf'
import J744_RoHS from './assets/J744_RoHS.pdf'
import J750_RoHS from './assets/J750_RoHS.pdf'
import K420_RoHS from './assets/K420_RoHS.pdf'
import P330_RoHS from './assets/P330_RoHS.pdf'
import P344_RoHS from './assets/P344_RoHS.pdf'
import P348_RoHS from './assets/P348_RoHS.pdf'
import P350_RoHS from './assets/P350_RoHS.pdf'
import P400_RoHS from './assets/P400_RoHS.pdf'
import P470_RoHS from './assets/P470_RoHS.pdf'
import P500_RoHS from './assets/P500_RoHS.pdf'
import P545_RoHS from './assets/P545_RoHS.pdf'
import P620_RoHS from './assets/P620_RoHS.pdf'
import P730_RoHS from './assets/P730_RoHS.pdf'
import P900_RoHS from './assets/P900_RoHS.pdf'
import P940_RoHS from './assets/P940_RoHS.pdf'
import P941_RoHS from './assets/P941_RoHS.pdf'
import P942_RoHS from './assets/P942_RoHS.pdf'
import P943_RoHS from './assets/P943_RoHS.pdf'
import P944_RoHS from './assets/P944_RoHS.pdf'
import P945_RoHS from './assets/P945_RoHS.pdf'
import P948_RoHS from './assets/P948_RoHS.pdf'
import T124_RoHS from './assets/T124_RoHS.pdf'
import T130_RoHS from './assets/T130_RoHS.pdf'
import T160_RoHS from './assets/T160_RoHS.pdf'
import T165_RoHS from './assets/T165_RoHS.pdf'
import T240_RoHS from './assets/T240_RoHS.pdf'
import T340_RoHS from './assets/T340_RoHS.pdf'
import T344_RoHS from './assets/T344_RoHS.pdf'
import T346_RoHS from './assets/T346_RoHS.pdf'
import T500_RoHS from './assets/T500_RoHS.pdf'
import T560_RoHS from './assets/T560_RoHS.pdf'
import T564_RoHS from './assets/T564_RoHS.pdf'
import T660_RoHS from './assets/T660_RoHS.pdf'
import T680_RoHS from './assets/T680_RoHS.pdf'
import T735_RoHS from './assets/T735_RoHS.pdf'
import T750_RoHS from './assets/T750_RoHS.pdf'
import T760_RoHS from './assets/T760_RoHS.pdf'
import T840_RoHS from './assets/T840_RoHS.pdf'
import T850_RoHS from './assets/T850_RoHS.pdf'
import T860_RoHS from './assets/T860_RoHS.pdf'
import V120_RoHS from './assets/V120_RoHS.pdf'
import V124_RoHS from './assets/V124_RoHS.pdf'
import V210_RoHS from './assets/V210_RoHS.pdf'
import V220_RoHS from './assets/V220_RoHS.pdf'
import V230_RoHS from './assets/V230_RoHS.pdf'
import V250_RoHS from './assets/V250_RoHS.pdf'
import V280_RoHS from './assets/V280_RoHS.pdf'
import V340_RoHS from './assets/V340_RoHS.pdf'
import V344_RoHS from './assets/V344_RoHS.pdf'
import V346_RoHS from './assets/V346_RoHS.pdf'
import V360_RoHS from './assets/V360_RoHS.pdf'
import V365_RoHS from './assets/V365_RoHS.pdf'
import V375_RoHS from './assets/V375_RoHS.pdf'
import V380_RoHS from './assets/V380_RoHS.pdf'
import V385_RoHS from './assets/V385_RoHS.pdf'
import V410_RoHS from './assets/V410_RoHS.pdf'
import V420_RoHS from './assets/V420_RoHS.pdf'
import V450_RoHS from './assets/V450_RoHS.pdf'
import V460_RoHS from './assets/V460_RoHS.pdf'
import V470_RoHS from './assets/V470_RoHS.pdf'
import V490_RoHS from './assets/V490_RoHS.pdf'
import V545_RoHS from './assets/V545_RoHS.pdf'
import V550_RoHS from './assets/V550_RoHS.pdf'
import V660_RoHS from './assets/V660_RoHS.pdf'
import V680_RoHS from './assets/V680_RoHS.pdf'
import V720_RoHS from './assets/V720_RoHS.pdf'
import V730_RoHS from './assets/V730_RoHS.pdf'
import V860_RoHS from './assets/V860_RoHS.pdf'

// Manual Import Files
import D100_MAN from './assets/D100MANC4.pdf'
import D200_MAN from './assets/D200MAND4.pdf'
import J240_MAN from './assets/J240MANB4.pdf'
import J270_MAN from './assets/J270MANB2.pdf'
import J720_MAN from './assets/J720MANB4.pdf'
import J724_MAN from './assets/J724MANE1.pdf'
import J730_MAN from './assets/J730MANC4.pdf'
import J736_MAN from './assets/J736MANB2.pdf'
import J744_MAN from './assets/J744MANA1.pdf'
import J750_MAN from './assets/J750MANA3.pdf'
import K420_MAN from './assets/K420MANB1.pdf'
import P330_MAN from './assets/P330MANA6.pdf'
// import P344_MAN from './assets/P344MAN.pdf'
import P348_MAN from './assets/P348MANB6.pdf'
import P350_MAN from './assets/P350MANB2.pdf'
import P400_MAN from './assets/P400MANC5.pdf'
import P470_MAN from './assets/P470MAND1.pdf'
import P500_MAN from './assets/P500MANE4.pdf'
import P545_MAN from './assets/P545MANB2.pdf'
import P620_MAN from './assets/P620MANC2.pdf'
import P730_MAN from './assets/P730MAND2.pdf'
import P900_MAN from './assets/P900MANA4.pdf'
import P940_MAN from './assets/P940MANB9.pdf'
import P941_MAN from './assets/P941MANC1.pdf'
import P945_MAN from './assets/P945MANB3.pdf'
import T124_MAN from './assets/T124MANA2.pdf'
import T130_MAN from './assets/T130MANC2.pdf'
import T160_MAN from './assets/T160MAND1.pdf'
import T165_MAN from './assets/T165MANE5.pdf'
import T240_MAN from './assets/T240MANC1.pdf'
import T340_MAN from './assets/T340MANC1.pdf'
import T344_T346_MAN from './assets/T344_T346MAND4.pdf'
import T500_MAN from './assets/T500MANA2.pdf'
import T560_MAN from './assets/T560MANL2.pdf'
import T564_MAN from './assets/T564MANL2.pdf'
import T660_MAN from './assets/T660MANE1.pdf'
import T680_MAN from './assets/T680MANC7.pdf'
import T735_MAN from './assets/T735MANB3.pdf'
import T750_MAN from './assets/T750MANC6.pdf'
import T760_MAN from './assets/T760MAND5.pdf'
import T840_MAN from './assets/T840MANA2.pdf'
import T850_MAN from './assets/T850MANC2.pdf'
import T860_MAN from './assets/T860MANB1.pdf'
import V120_V124_MAN from './assets/V120_V124MANC5.pdf'
import V180_MAN from './assets/V180MANA3.pdf'
import V210_MAN from './assets/V210MAND2.pdf'
import V220_MAN from './assets/V220MANE3.pdf'
import V230_MAN from './assets/V230MAND3.pdf'
import V250_MAN from './assets/V250MANB2.pdf'
import V280_MAN from './assets/V280MANB2.pdf'
import V340_MAN from './assets/V340MAND6.pdf'
import V344_V346_MAN from './assets/V344_V346MANC3.pdf'
import V350_MAN from './assets/V350MANB5.pdf'
import V360_MAN from './assets/V360MANC1.pdf'
import V365_MAN from './assets/V365MANB2.pdf'
import V370_MAN from './assets/V370MAND1.pdf'
import V375_MAN from './assets/V375MANE1.pdf'
import V380_MAN from './assets/V380MANB5.pdf'
import V385_MAN from './assets/V385MANC6.pdf'
import V410_MAN from './assets/V410MANB5.pdf'
import V420_MAN from './assets/V420MANE1.pdf'
import V450_MAN from './assets/V450MANG2.pdf'
import V460_MAN from './assets/V460MANC3.pdf'
import V470_MAN from './assets/V470MANH1.pdf'
import V490_MAN from './assets/V490MANF1.pdf'
import V545_MAN from './assets/V545MANF1.pdf'
import V550_MAN from './assets/V550MANB4.pdf'
import V660_MAN from './assets/V660MANB5.pdf'
import V680_MAN from './assets/V680MANC3.pdf'
import V720_MAN from './assets/V720MAND2.pdf'
import V730_MAN from './assets/V730MANB4.pdf'
import V860_MAN from './assets/V860MAND1.pdf'
import V880_MAN from './assets/V880MAND4.pdf'

// Certificates of Conformance Export Array
export const CoCArr = [
    D100_CoC,
    D200_CoC,
    J240_CoC,
    J270_CoC,
    J720_CoC,
    J724_CoC,
    J730_CoC,
    J736_CoC,
    J744_CoC,
    J750_CoC,
    K420_CoC,
    P330_CoC,
    P344_CoC,
    P348_CoC,
    P350_CoC,
    P400_CoC,
    P470_CoC,
    P500_CoC,
    P545_CoC,
    P620_CoC,
    P730_CoC,
    P900_CoC,
    P940_CoC,
    P941_CoC,
    P942_CoC,
    P943_CoC,
    P944_CoC,
    P945_CoC,
    P948_CoC,
    T124_CoC,
    T130_CoC,
    T160_CoC,
    T165_CoC,
    T240_CoC,
    T340_CoC,
    T344_CoC,
    T346_CoC,
    T400_CoC,
    T500_CoC,
    T560_CoC,
    T564_CoC,
    T660_CoC,
    T680_CoC,
    T735_CoC,
    T750_CoC,
    T760_CoC,
    T840_CoC,
    T850_CoC,
    T860_CoC,
    V120_CoC,
    V124_CoC,
    V180_CoC,
    V210_CoC,
    V220_CoC,
    V230_CoC,
    V250_CoC,
    V280_CoC,
    V340_CoC,
    V344_CoC,
    V346_CoC,
    V360_CoC,
    V365_CoC,
    V375_CoC,
    V380_CoC,
    V385_CoC,
    V410_CoC,
    V420_CoC,
    V450_CoC,
    V460_CoC,
    V470_CoC,
    V490_CoC,
    V545_CoC,
    V550_CoC,
    V660_CoC,
    V680_CoC,
    V720_CoC,
    V730_CoC,
    V860_CoC,
    V880_CoC,
]

// Statements of Volatility Export Array
export const SoVArr = [
    J724_SOV,
    J730_SOV,
    K420_SOV,
    P330_SOV,
    P344_SOV,
    P348_SOV,
    P350_SOV,
    P900_SOV,
    P940_P941_P942_P943_P944_P945_P948_SOV,
    T165_SOV,
    T344_SOV,
    T346_SOV,
    T680_SOV,
    T735_SOV,
    V120_SOV,
    V124_SOV,
    V210_SOV,
    V220_SOV,
    V230_SOV,
    V250_SOV,
    V340_SOV,
    V360_SOV,
    V375_SOV,
    V410_SOV,
    V420_SOV,
    V450_SOV,
    V470_SOV,
    V490_SOV,
    V545_SOV,
    V550_SOV,
]

// STEP Export Array
export const StepArr = [
    D100_STEP,
    J240_J730_J750_STEP, 
    J270_STEP, 
    J720_STEP,
    J724_STEP, 
    K420_STEP, 
    P344_P348_P350_P730_STEP, 
    P940_STEP,
    T124_T240_T340_T344_T346_T750_T760_T860_STEP, 
    T500_STEP, 
    T560_T564_STEP, 
    T660_STEP,
    T735_STEP, 
    V344_V346_STEP, 
    V375_STEP, 
]

// CE Certificates Export Array
export const CEArr = [
    P500_CE,
    P545_CE,
]

// Block Diagram Export Array
export const BDArr = [
    P330_BD,
    P348_BD,
    P350_BD,
    P400_BD,
    P470_BD,
    P500_BD,
    P545_BD,
    P620_BD,
    P730_BD,
    P900_BD,
    P940_BD,
    P941_BD,
    P945_BD,
    T124_BD,
    T130_BD,
    T340_BD,
    T344_T346_BD,
    T500_BD,
    T560_T564_BD,
    T660_BD,
    T680_BD,
    T840_BD,
    V120_BD,
    V124_BD,
    V210_BD,
    V220_BD,
    V230_BD,
    V250_BD,
    V280_BD,
    V340_BD,
    V344_V346_BD,
    V360_BD,
    V365_BD,
    V375_BD,
    V410_BD,
    V420_BD,
    V450_BD,
    V470_BD,
    V490_BD,
    V545_BD,
    V660_BD,
    V680_BD,
    V720_BD,
    V880_BD,
]

// RoHS Certificates Export Array 
export const rohsArr = [
    D100_RoHS,
    D200_RoHS,
    J240_RoHS,
    J270_RoHS,
    J720_RoHS,
    J724_RoHS,
    J730_RoHS,
    J736_RoHS,
    J744_RoHS,
    J750_RoHS,
    K420_RoHS,
    P330_RoHS,
    P344_RoHS,
    P348_RoHS,
    P350_RoHS,
    P400_RoHS,
    P470_RoHS,
    P500_RoHS,
    P545_RoHS,
    P620_RoHS,
    P730_RoHS,
    P900_RoHS,
    P940_RoHS,
    P941_RoHS,
    P942_RoHS,
    P943_RoHS,
    P944_RoHS,
    P945_RoHS,
    P948_RoHS,
    T124_RoHS,
    T130_RoHS,
    T160_RoHS,
    T165_RoHS,
    T240_RoHS,
    T340_RoHS,
    T344_RoHS,
    T346_RoHS,
    T500_RoHS,
    T560_RoHS,
    T564_RoHS,
    T660_RoHS,
    T680_RoHS,
    T735_RoHS,
    T750_RoHS,
    T760_RoHS,
    T840_RoHS,
    T850_RoHS,
    T860_RoHS,
    V120_RoHS,
    V124_RoHS,
    V210_RoHS,
    V220_RoHS,
    V230_RoHS,
    V250_RoHS,
    V280_RoHS,
    V340_RoHS,
    V344_RoHS,
    V346_RoHS,
    V360_RoHS,
    V365_RoHS,
    V375_RoHS,
    V380_RoHS,
    V385_RoHS,
    V410_RoHS,
    V420_RoHS,
    V450_RoHS,
    V460_RoHS,
    V470_RoHS,
    V490_RoHS,
    V545_RoHS,
    V550_RoHS,
    V660_RoHS,
    V680_RoHS,
    V720_RoHS,
    V730_RoHS,
    V860_RoHS
]

// Manuals Export Array
export const ManArr = [
    D100_MAN,
    D200_MAN,
    J240_MAN,
    J270_MAN,
    J720_MAN,
    J724_MAN,
    J730_MAN,
    J736_MAN,
    J744_MAN,
    J750_MAN,
    K420_MAN,
    P330_MAN,
    // P344_MAN,
    P348_MAN,
    P350_MAN,
    P400_MAN,
    P470_MAN,
    P500_MAN,
    P545_MAN,
    P620_MAN,
    P730_MAN,
    P900_MAN,
    P940_MAN,
    P941_MAN,
    P945_MAN,
    T124_MAN,
    T130_MAN,
    T160_MAN,
    T165_MAN,
    T240_MAN,
    T340_MAN,
    T344_T346_MAN,
    T500_MAN,
    T560_MAN,
    T564_MAN,
    T660_MAN,
    T680_MAN,
    T735_MAN,
    T750_MAN,
    T760_MAN,
    T840_MAN,
    T850_MAN,
    T860_MAN,
    V120_V124_MAN,
    V180_MAN,
    V210_MAN,
    V220_MAN,
    V230_MAN,
    V250_MAN,
    V280_MAN,
    V340_MAN,
    V344_V346_MAN,
    V350_MAN,
    V360_MAN,
    V365_MAN,
    V370_MAN,
    V375_MAN,
    V380_MAN,
    V385_MAN,
    V410_MAN,
    V420_MAN,
    V450_MAN,
    V460_MAN,
    V470_MAN,
    V490_MAN,
    V545_MAN,
    V550_MAN,
    V660_MAN,
    V680_MAN,
    V720_MAN,
    V730_MAN,
    V860_MAN,
    V880_MAN,
]