export const RedirectOldPathToNewPathDict = {
  "/categories/accessories.shtml": "/",
  "/categories/digital_delay_generators.shtml": "/Category/DDG",
  "/categories/laser_drivers.shtml": "/Category/LDC",
  "/categories/legacy.shtml": "/Category/LEG",
  "/categories/measurement_simulation.shtml": "/Category/MAS",
  "/categories/oem_embedded.shtml": "/Category/OEM",
  "/categories/photonics.shtml": "/Category/PHO",
  "/categories/products.shtml": "/Products",
  "/categories/vme.shtml": "/Category/VME",
  "/categories/waveform_generators.shtml": "/Category/WFG",
  "/company/about.shtml": "/About",
  "/company/careers.shtml": "/Careers",
  "/company/company.shtml": "/About",
  "/company/customers.shtml": "/Customers",
  "/company/partners.shtml": "/Partners",
  "/company/testimonials.shtml": "/Testimonials",
  "/contact/contact.shtml": "/Contact",
  "/contact/directions.shtml": "/Contact",
  "/contact/sales.shtml": "/Contact",
  "/downloads/block_diagrams.shtml": "/",
  "/downloads/catalog.shtml": "/",
  "/downloads/datasheets.shtml": "/",
  "/downloads/downloads.shtml": "/",
  "/downloads/manuals.shtml": "/",
  "/DSS/D100DS.shtml": "/Product/D100",
  "/DSS/D200DS.shtml": "/Product/D200",
  "/DSS/gate_triggered_oscillators.shtml": "/Category/OEM",
  "/DSS/J240DS.shtml": "/Product/J240",
  "/DSS/J270DS.shtml": "/Product/J270",
  "/DSS/J720DS.shtml": "/Product/J720",
  "/DSS/J724DS.shtml": "/Product/J724",
  "/DSS/J730DS.shtml": "/Product/J730",
  "/DSS/J736DS.shtml": "/Product/J736",
  "/DSS/J750DS.shtml": "/Product/J750",
  "/DSS/M460DS.shtml": "/Category/LEG",
  "/DSS/M520DS.shtml": "/Category/LEG",
  "/DSS/M550DS.shtml": "/Category/LEG",
  "/DSS/M680DS.shtml": "/Category/LEG",
  "/DSS/P330DS.shtml": "/Product/P330",
  "/DSS/P348DS.shtml": "/Product/P348",
  "/DSS/P350DS.shtml": "/Product/P350",
  "/DSS/P400DS.shtml": "/Product/P400",
  "/DSS/P470DS.shtml": "/Product/P470",
  "/DSS/P500DS.shtml": "/Product/P500",
  "/DSS/P545DS.shtml": "/Product/P545",
  "/DSS/P620DS.shtml": "/Product/P620",
  "/DSS/P730DS.shtml": "/Product/P730",
  "/DSS/P900DS.shtml": "/Product/P900",
  "/DSS/T124DS.shtml": "/Product/T124",
  "/DSS/T130DS.shtml": "/Product/T130",
  "/DSS/T160DS.shtml": "/Product/T160",
  "/DSS/T165DS.shtml": "/Product/T165",
  "/DSS/T240DS.shtml": "/Product/T240",
  "/DSS/T340DS.shtml": "/Product/T340",
  "/DSS/T344DS.shtml": "/Product/T344",
  "/DSS/T346DS.shtml": "/Product/T346",
  "/DSS/T560DS.shtml": "/Product/T560",
  "/DSS/T564DS.shtml": "/Product/T564",
  "/DSS/T680DS.shtml": "/Product/T680",
  "/DSS/T735DS.shtml": "/Product/T735",
  "/DSS/T750DS.shtml": "/Product/T750",
  "/DSS/T760DS.shtml": "/Product/T760",
  "/DSS/T840DS.shtml": "/Product/T840",
  "/DSS/T850DS.shtml": "/Product/T850",
  "/DSS/T860DS.shtml": "/Product/T860",
  "/DSS/V120DS.shtml": "/Product/V120",
  "/DSS/V124DS.shtml": "/Product/V124",
  "/DSS/V180DS.shtml": "/Product/V180",
  "/DSS/V210DS.shtml": "/Product/V210",
  "/DSS/V220DS.shtml": "/Product/V220",
  "/DSS/V230DS.shtml": "/Product/V230",
  "/DSS/V250DS.shtml": "/Product/V250",
  "/DSS/V280DS.shtml": "/Product/V280",
  "/DSS/V340DS.shtml": "/Product/V340",
  "/DSS/V344DS.shtml": "/Product/V344",
  "/DSS/V346DS.shtml": "/Product/V346",
  "/DSS/V350DS.shtml": "/Product/V350",
  "/DSS/V360DS.shtml": "/Product/V360",
  "/DSS/V365DS.shtml": "/Product/V365",
  "/DSS/V370DS.shtml": "/Product/V370",
  "/DSS/V375DS.shtml": "/Product/V375",
  "/DSS/V380DS.shtml": "/Product/V380",
  "/DSS/V385DS.shtml": "/Product/V385",
  "/DSS/V410DS.shtml": "/Product/V410",
  "/DSS/V420DS.shtml": "/Product/V420",
  "/DSS/V450DS.shtml": "/Product/V450",
  "/DSS/V460DS.shtml": "/Product/V460",
  "/DSS/V470DS.shtml": "/Product/V470",
  "/DSS/V490DS.shtml": "/Product/V490",
  "/DSS/V545DS.shtml": "/Product/V545",
  "/DSS/V550DS.shtml": "/Product/V550",
  "/DSS/V660DS.shtml": "/Product/V660",
  "/DSS/V680DS.shtml": "/Product/V680",
  "/DSS/V720DS.shtml": "/Product/V720",
  "/DSS/V730DS.shtml": "/Product/V730",
  "/DSS/V850DS.shtml": "/Product/V850",
  "/DSS/V851DS.shtml": "/Product/V851",
  "/DSS/V860DS.shtml": "/Product/V860",
  "/DSS/V880DS.shtml": "/Product/V880",
  "/DSS/V951DS.shtml": "/Category/LEG",
  "/DSS/V980DS.shtml": "/Category/LEG",
  "/dyn/login.php": "/Login",
  "/dyn/profile.php": "/Account",
  "/dyn/quote.php": "/Contact",
  "/dyn/registration.php": "/Register",
  "/dyn/send_passwd.php": "/Account",
  "/dyn/support.php": "/Contact",
  "/index.shtml": "/",
  "/news/coming_soon.shtml": "/",
  "/news/new.shtml": "/",
  "/news/news.shtml": "/",
  "/news/pressroom.shtml": "/",
};
